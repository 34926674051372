import {
  EventDescriptionBox,
  CategoryDropDown,
  PriorityDropDown,
  ProcessStageDropDown
} from '@hconnect/common/components/shiftEventFormFields'
import {EventHighlight} from '@hconnect/common/components/shiftEventFormFields/EventHighlight'
import {
  REGULAR_SIZE,
  INPUT_FORM_FIELD_SX,
  MAX_DESCRIPTION_CHARACTER_LIMIT
} from '@hconnect/common/consts'
import type {TaskChecklistsOption} from '@hconnect/common/types'
import {EventType, Priority, TaskRepetition, User} from '@hconnect/common/types'
import {removeMarkdownTags} from '@hconnect/common/utils'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  shouldNotBeEmptyStringWithMaxLength,
  ValidatorConfig
} from '@hconnect/common/validators'
import {Grid, Switch, TextField, Box} from '@mui/material'
import _, {isArray} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {ChecklistsInputSettings} from '../../types/shareEventForm.types'
import {
  BaseEvent,
  ShiftEvent,
  ShiftEventAndUpdateEvent,
  Task
} from '../../types/shiftHandover.types'
import {isCustomRepetition, RepetitionInputsSettings} from '../../types/taskRepetitionInfo.types'
import {ShiftPicker} from '../dateTimeAndShift/ShiftPicker'
import {EquipmentSearchContainer} from '../eventProperties/EquipmentSearchContainer'
import {EventInfo} from '../eventProperties/EventInfo'
import {EventStatusDropDown} from '../eventProperties/EventStatus'
import {EventTypeDropdownBox} from '../eventProperties/EventTypeDropdownBox'
import {TaskChecklistsField} from '../eventProperties/TaskChecklistsField'
import {UserSearchField} from '../eventProperties/UserSearchField'
import {TaskRepetitionSettings} from '../repetitiveTask/TaskRepetitionSettings'

type TypeOfTask = Task & {
  checklists: TaskChecklistsOption[]
}

type PossibleErrors = keyof TypeOfTask | 'equipmentNumber'

export type Props = {
  item: Partial<TypeOfTask>
  repetitionInputsSettings: RepetitionInputsSettings
  checklistsInputSettings: ChecklistsInputSettings
  allowCancellation: boolean
  update: <K extends keyof TypeOfTask>(key: K, value: TypeOfTask[K] | EventType) => void
  updateMultiPart: (delta) => void
  validationError: Map<PossibleErrors, string>
  editWithTaskSchedules: boolean
  disableDoNotStartBefore: boolean
  originalAssignees?: User[]
  children: React.ReactNode
}

// As the UI has to reflect the visible fields and knows best which of them is Required,
// the Validator config is put here to have both aligned at all time.

export const taskValidatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> = new Map([
  ['category', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  [
    'description',
    shouldNotBeEmptyStringWithMaxLength<ShiftEventAndUpdateEvent>(
      MAX_DESCRIPTION_CHARACTER_LIMIT,
      removeMarkdownTags
    )
  ],
  ['dueDate', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['status', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['title', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()]
])

export const taskDefaultObject = (): Partial<TypeOfTask> => {
  return {
    description: '',
    eventType: 'task',
    status: 'pending',
    title: '',
    checklists: []
  }
}

/**
 * if you consider using this component, check first src/container/EventFormContainer.tsx,
 * as it handles the validation and the stuff around this form,
 * it might already solve all your needs
 */
/* eslint-disable complexity */
export const TaskForm: React.FC<Props> = ({
  children,
  repetitionInputsSettings: originalRepetitionInputsSettings,
  checklistsInputSettings,
  allowCancellation,
  item,
  update,
  updateMultiPart,
  validationError,
  editWithTaskSchedules,
  originalAssignees = [],
  disableDoNotStartBefore
}) => {
  const {t} = useTranslation()
  const {plantId} = useConfig()
  const endOfToday = useConfig().plantNow().endOf('day')
  const startOfToday = useConfig().plantNow().startOf('day')

  const assignee = item.assignees || []
  const checklists = item.checklists || checklistsInputSettings.initialValue || []

  const repetitionInputsSettings: RepetitionInputsSettings = {
    ...originalRepetitionInputsSettings,
    disableCheckbox: checklists.length ? true : originalRepetitionInputsSettings.disableCheckbox
  }

  const dueDateHasError = typeof validationError.get('dueDate') === 'string'

  const getErrorText = (key: PossibleErrors) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const updateTaskRepetition = useCallback(
    (next: TaskRepetition | null) => {
      if (repetitionInputsSettings.disableSelect && repetitionInputsSettings.disableCheckbox) return

      if (next) {
        update('priority', undefined)
      }

      update('repetitionInfo', next || null)
    },
    [update, repetitionInputsSettings.disableSelect, repetitionInputsSettings.disableCheckbox]
  )

  return (
    <>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          data-test-id="event-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('shiftEvent.label.title')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            update('title', event.target.value)
          }
          required
          value={item.title}
          variant="filled"
          InputProps={INPUT_FORM_FIELD_SX}
        />
        <EventHighlight
          title={t('eventsTable.action.highlight')}
          value={item.isHighlighted}
          onChange={(value) => update('isHighlighted', value)}
        />
      </Box>

      {'created' in item && <EventInfo item={item as Partial<ShiftEvent>} />}

      <Grid container my={0.5} spacing={2} alignItems="center">
        <Grid item {...REGULAR_SIZE}>
          <EventTypeDropdownBox
            item={item as BaseEvent}
            value="task"
            updateMultiPart={updateMultiPart}
            disabled={'repetitionInfo' in item && item.repetitionInfo !== null}
          />
        </Grid>

        <Grid item {...REGULAR_SIZE}>
          <EventStatusDropDown
            data-test-id="event-form-status"
            errorText={getErrorText('status')}
            onChange={(value) => update('status', value)}
            required
            allow={allowCancellation ? 'cancelled' : undefined}
            value={item.status}
            disabled={editWithTaskSchedules}
          />
        </Grid>
        <Grid item {...REGULAR_SIZE}>
          <CategoryDropDown
            data-test-id="event-form-category"
            errorText={getErrorText('category')}
            onChange={(value) => update('category', value)}
            required
            value={item.category}
          />
        </Grid>
        <Grid item {...REGULAR_SIZE}>
          <PriorityDropDown
            data-test-id="event-form-priority"
            errorText={getErrorText('priority')}
            onChange={(value) => update('priority', value)}
            value={item.priority as Priority}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Note: Due date can be changed while editing Single task from custom series */}
          {(repetitionInputsSettings.disableSelect ||
            !(item.repetitionInfo && isCustomRepetition(item.repetitionInfo))) && (
            <ShiftPicker
              error={dueDateHasError}
              label={<Box>{t('shiftEvent.label.dueDate')} *</Box>}
              utcDate={item.dueDate || endOfToday.toISOString()}
              utcStartDate={item.doNotStartBefore}
              enableSelectRange={!disableDoNotStartBefore}
              onChange={(pickedShift) => {
                if (isArray(pickedShift)) {
                  const [start, end] = pickedShift
                  update('doNotStartBefore', start.utcEndDate)
                  update('dueDate', end.utcEndDate)
                  update('repetitionInfo', null)
                } else {
                  update('dueDate', pickedShift.utcEndDate)
                  update('doNotStartBefore', undefined)
                }
              }}
            />
          )}
        </Grid>
        <Grid container m={0} alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <UserSearchField
              label={`${t('shiftEvent.label.assignee')} (${t('shiftEvent.label.optional')})`}
              errorText={getErrorText('assignees')}
              data-test-id="assigneeSearch"
              onChange={(newAssignee) => {
                if (newAssignee && !_.isEqual(newAssignee, assignee)) {
                  const disableToggle = newAssignee.every((newVal) =>
                    originalAssignees.some((oldVal) => newVal.id === oldVal.id)
                  )
                  updateMultiPart({
                    assignees: newAssignee,
                    toggleAssigneeNotification: !disableToggle
                  })
                } else if (!newAssignee) {
                  updateMultiPart({
                    assignees: []
                  })
                }
              }}
              value={assignee}
            />
          </Grid>
          {'toggleAssigneeNotification' in item && (
            <Box pl={2}>
              {t('shiftEvent.action.assigneeNotificationToggle')}{' '}
              <Switch
                checked={item.toggleAssigneeNotification}
                data-test-id="events-notify-assignee-toggle"
                onChange={() =>
                  update('toggleAssigneeNotification', !item.toggleAssigneeNotification)
                }
                color="primary"
              />
            </Box>
          )}
        </Grid>

        <TaskRepetitionSettings
          repetitionInputsSettings={repetitionInputsSettings}
          onChange={updateTaskRepetition}
          settings={item.repetitionInfo || null}
          gridSetting={REGULAR_SIZE}
          defaultStartDate={
            // if due date is a valid date in the future, then start date should be due date for custom repetition
            // Start date is not required for quick repetition types
            item.dueDate && startOfToday.isBefore(item.dueDate)
              ? item.dueDate
              : endOfToday.toISOString()
          }
        />

        <Grid item xs={12}>
          <EquipmentSearchContainer
            data-test-id="event-form-equipmentNumber"
            errorText={getErrorText('equipmentNumber')}
            updateMultiPart={updateMultiPart}
            value={item as BaseEvent}
          />
        </Grid>
        <Grid item {...REGULAR_SIZE}>
          <ProcessStageDropDown
            plantId={plantId}
            data-test-id="event-form-processStage"
            errorText={getErrorText('processStage')}
            onChange={(value) => update('processStage', value)}
            value={item.processStage}
          />
        </Grid>

        <Grid item xs={12}>
          <EventDescriptionBox
            current={item.description || ''}
            errorCode={validationError.get('description')}
            onChange={(next) => update('description', next)}
            required
          />
        </Grid>

        {checklistsInputSettings.showChecklists && (
          <Grid item xs={12}>
            <TaskChecklistsField
              errorText={validationError.get('checklists')}
              onChange={(next) => update('checklists', next)}
              value={checklists}
              disabled={checklistsInputSettings.disableSelect}
            />
          </Grid>
        )}
      </Grid>
      {children}
    </>
  )
}
